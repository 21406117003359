var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "tile-bar" }, [
        _c(
          "h1",
          [
            _c("router-link", { attrs: { to: "/" } }, [
              _vm._v("Felsampel des Elbsandsteingebirges")
            ])
          ],
          1
        ),
        _vm._v(" "),
        _vm._m(0)
      ]),
      _vm._v(" "),
      _c("router-view"),
      _vm._v(" "),
      _c("a", { attrs: { id: "last-update" } }),
      _vm._v(" "),
      _c("h3", [_vm._v("Datenstand")]),
      _vm._v(" "),
      _c(
        "p",
        [
          _vm._v("Die Daten wurden zuletzt aktualisiert am: "),
          _c("felsampel-last-update")
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "p",
        [
          _vm._v("\n      Wir freuen uns über\n      "),
          _c(
            "router-link",
            {
              attrs: {
                to: {
                  name: "region",
                  params: {
                    regionId: _vm.$route.params.regionId,
                    levelId: _vm.$route.params.levelId,
                    section: "feedback"
                  }
                }
              }
            },
            [_vm._v("alle Rückmeldungen")]
          ),
          _vm._v(".\n\n      Näheres zur "),
          _c(
            "router-link",
            {
              attrs: {
                to: {
                  name: "region",
                  params: {
                    regionId: _vm.$route.params.regionId,
                    levelId: _vm.$route.params.levelId,
                    section: "presentation"
                  }
                }
              }
            },
            [_vm._v("Darstellung findet sich unten")]
          ),
          _vm._v(".\n  ")
        ],
        1
      ),
      _vm._v(" "),
      _c("h2", [_vm._v("Über die Felsampel")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n      Die Felsampel wird seit 2015 von der ehrenamtlichen „Projektgruppe Felsampel“ im Sächsischen\n      Bergsteigerbund e.V. konzipiert und umgesetzt. Als online kostenlos abrufbares Tool schafft sie für\n      Bergsteiger in der Sächsischen Schweiz eine Handlungsempfehlung, ob und wann nach Niederschlägen wieder\n      geklettert werden kann.\n  "
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n      Klettern an nassem Fels gefährdet die empfindlichen Sandsteinformationen und bringt gleichzeitig die\n      Kletterer in Gefahr. Gründe dafür sind einerseits die verminderte Reibung der Felsoberfläche und\n      andererseits die Wasserlöslichkeit des Bindemittels im Sandstein. Der damit verbundene\n      Festigkeitsverlust lässt sich nur mit viel Erfahrung oder oftmals überhaupt nicht einschätzen.\n  "
        )
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "warning" }, [
        _vm._v(
          "\n      Die Angaben der Felsampel haben empfehlenden Charakter und sind in keiner Weise bindend. Sie ersetzen\n      keine fundierten Gebietskenntnisse oder grundlegende Fähigkeiten des Bergsports, zu denen auch die\n      individuelle Risikobewertung der angestrebten Tätigkeit zählt. Der Sächsische Bergsteigerbund übernimmt\n      keine Haftung für etwaige Schäden, die durch die Verwendung der Felsampel entstanden sein könnten!\n  "
        )
      ]),
      _vm._v(" "),
      _c("a", { attrs: { id: "feedback" } }),
      _vm._v(" "),
      _c("h3", [_vm._v("Rückmeldungen")]),
      _vm._v(" "),
      _c("h4", [
        _vm._v("Ihr wollt an der Verbesserung der Felsampel mitwirken?")
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          '\n      Zum Eintragen eurer subjektiven Felsfeuchte-Empfindung, die ihr im Gelände erlebt, steht ein\n      Online-Fragebogen zur Verfügung. Bitte tragt bei "Name" einen frei wählbaren Benutzernamen ein. Diese\n      Information hilft uns sehr, eure Bewertungen vergleichbar zu machen und die Aussagekraft der Felsampel\n      schneller zu verbessern!\n  '
        )
      ]),
      _vm._v(" "),
      _vm.herbstzeit
        ? _c("em", [
            _vm._v(
              '\n      Jetzt im Herbst ist es für uns auch besonders wichtig, zu wissen, ob es "Bömischen Nebel" gab!\n  '
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.showReportForm
        ? [
            _c("p", [
              _c(
                "a",
                {
                  on: {
                    click: function($event) {
                      _vm.showReportForm = true
                    }
                  }
                },
                [_vm._v("Fragebogen anzeigen.")]
              )
            ])
          ]
        : [
            _c("felsampel-user-report"),
            _vm._v(" "),
            _c(
              "a",
              {
                on: {
                  click: function($event) {
                    _vm.showReportForm = false
                  }
                }
              },
              [_vm._v("Fragebogen ausblenden.")]
            )
          ],
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n      Bitte habt Verständnis dafür, dass wir mit den analogen Fragebögen nicht allzu viel anfangen können. Für\n      die Übertragung haben wir einfach nicht die Kapazitäten. Deswegen sind wir auf eure Mitarbeit\n      angewiesen. Selbst, wenn ihr erst einige Tage später zum Übertragen ins Online-Formular kommt, ist uns\n      schon immens geholfen! Jede gewissenhafte Eintragung der subjektiven Wahrnehmung erhöht die realistische\n      Aussagekraft der Felsampel!\n  "
        )
      ]),
      _vm._v(" "),
      _c("h4", [_vm._v("Fragen, Anregungen, Meldung von Problemen?")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n      Wir sind bemüht, die Felsampel im Rahmen unserer Möglichkeiten stetig zu optimieren. Für konstruktive\n      Anmerkungen könnt ihr uns eine Email schreiben an:\n  "
        )
      ]),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "mail-address",
          attrs: {
            href: _vm.deobfuscate(
              "bWFpbHRvOmZlbHNh" + "bXBlbEBiZXJnc" + "3RlaWdlcmJ1bmQuZGU="
            )
          }
        },
        [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.deobfuscate(
                  "ZmVs" + "c2FtcGVsQGJlcmdz" + "dGVpZ2VyYnVuZ" + "C5kZQ=="
                )
              ) +
              "\n  "
          )
        ]
      ),
      _vm._v(" "),
      _c("h3", [_vm._v("Unterstützung")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n      Dir gefällt, was du siehst? Du möchtest dich erkenntlich zeigen? Mit einer kleinen Aufmerksamkeit kannst\n      du den Betrieb und den weiteren Ausbau der Felsampel gezielt unterstützen.\n  "
        )
      ]),
      _vm._v(" "),
      _vm._m(2),
      _vm._v(" "),
      _c("h3", [_vm._v("Feuchtemodellierung")]),
      _vm._v(" "),
      !_vm.showLongExplanation
        ? [
            _c("p", [
              _vm._v(
                "\n          Die Feuchtemodellierung besteht aus zwei Modulen:\n      "
              )
            ]),
            _vm._v(" "),
            _vm._m(3),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n          Die Berechnung der Verdunstungsrate ist rein physikbasiert und erfolgt direkt aus der Bilanz der\n          wichtigsten Energieströme an der Felsoberfläche (Energieerhaltungssatz): Sonneneinstrahlung,\n          Wärmeabstrahlung, Wärmetransport durch Wind und der für die Verdunstung zur Verfügung stehende\n          Energiefluss. Die Felsfeuchtemodellierung beruht ebenfalls auf einer Bilanz, nämlich jener der\n          Wasserflüsse: Der ankommende Regen teilt sich auf in direkten Abfluss, Verdunstung, und\n          Feuchtetransport in und aus dem Fels. Aus dem Feuchtetransport wird dann mittels der Erhaltung der\n          Wassermenge die Felsfeuchte nahe der Oberfläche und in der Tiefe berechnet.\n      "
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n          Diese Bilanzen hängen ab von Tages- und Jahreszeit, Wolkenbedeckung, Sonne/Schatten, Wind,\n          Temperatur und relative Luftfeuchte. Dazu kommen noch Felsparameter wie Kompaktheit der Oberfläche\n          und Porosität.\n      "
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _c(
                "a",
                {
                  on: {
                    click: function($event) {
                      _vm.showLongExplanation = true
                    }
                  }
                },
                [
                  _vm._v(
                    "Weitere Details zu Feuchtemodellierung und Darstellung\n          anzeigen."
                  )
                ]
              )
            ])
          ]
        : [
            _c("p", [
              _vm._v(
                '\n          Um die Qualität der Felsfeuchte-Prognosen, aber auch ihre Grenzen abschätzen zu können,\n          folgt hier eine kurze Beschreibung dessen, was "unter der Haube" alles so abläuft. Dies kann auch\n          bei der Bedienung der Felsampel in Zweifelsfällen helfen, z.B.\n      '
              )
            ]),
            _vm._v(" "),
            _vm._m(4),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n          Die Feuchtemodellierung besteht aus zwei Modulen:\n      "
              )
            ]),
            _vm._v(" "),
            _vm._m(5),
            _vm._v(" "),
            _c("h4", [_vm._v("Verdunstungsrate bzw. Evaporation")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n          Die Berechnung der Evaporation ist rein physikbasiert und erfolgt direkt aus dem\n          Energieerhaltungssatz: Die Summe der eingehenden Energieflüsse (Einheit W/m²) ist gleich der Summe\n          der ausgehenden Flüsse, und zwar überall, insbesondere in der Atmosphäre bzw. den Wolken und auf der\n          Erdoberfläche.\n      "
              )
            ]),
            _vm._v(" "),
            _vm._m(6),
            _vm._v(" "),
            _c("h5", [_vm._v("Bilanz in der Atmosphäre")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                '\n          Die aus dem Weltraum kommenden Sonnenstrahlen werden zunächst teilweise an den Wolken oder am\n          Wasserdampf der Atmosphäre reflektiert oder absorbiert, nur der dritte Anteil schafft es bis auf die\n          Erdoberfläche bzw. an den Fels. Allerdings kommt auch dieser Strahl nicht ungerupft davon, lediglich\n          ein Teil erreicht als direkter Strahl die Oberfläche, während der andere Teil von den Wolken\n          und dem Wasserdampf gestreut wird und als "diffuse" Strahlung an die Oberfläche gelangt. Diese\n          Unterscheidung ist für die Felsampel wichtig, da sie es erlaubt, den Unterschied zwischen sonnigen\n          und schattigen Felsen zu modellieren. Bei einer geschlossenen Wolkenbedeckung gibt es natürlich\n          immer nur den diffusen Anteil. In der Nacht fällt die Einstrahlung ganz weg, da die Einstrahlung der\n          Sterne und die reflektierte Strahlung vom Mond vernachlässigbar sind. Die ankommende Einstrahlung\n          hängt vor allem von der Jahres- und Tageszeit, vom Längen- und Breitengrad, von der Wolkenbedeckung\n          und von der Schattigkeit ab und wird als Funktion dieser Größen berechnet. Die Wolkenbedeckung kommt\n          dabei von den Wetterstationen, während der Nutzer die Schattigkeit auswählt. Manche Wetterstationen\n          geben die Einstrahlung auf schattenfreiem Gelände auch direkt an, womit man die Berechnungen\n          kontrollieren kann.\n      '
              )
            ]),
            _vm._v(" "),
            _c("h5", [_vm._v("Bilanz an der Felsoberfläche")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n          Neben der Energieflussbilanz in der Atmosphäre ist vor allem die Bilanz an der Felsoberfläche\n          wichtig. Wir unterscheiden fünf Komponenten (siehe obige Abbildung):\n      "
              )
            ]),
            _vm._v(" "),
            _vm._m(7),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                '\n          Ein weiterer Anteil – der Wärmestrom in den Fels oder aus diesem heraus – wird bisher nicht\n          berücksichtigt, obwohl auch er die Feuchte beeinflussen kann. Ist es draußen feuchtwarm und der Fels\n          kalt, ist dieser Wärmestrom nach innen gerichtet und der Fels "schwitzt" von innen.\n      '
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n          Da man die ersten drei Flüsse (Einstrahlung, Reflexion und Infrarot-Abstrahlung) direkt berechnen\n          bzw. abschätzen und damit zur Netto-Einstrahlung bilanzieren kann, ist aufgrund der Energieerhaltung\n          auch die Summe des konvektiven und des zur Verdunstung zur Verfügung stehenden Energieflusses\n          bekannt. Allerdings ist es bedeutend schwieriger, diese separat zu bestimmen.\n      "
              )
            ]),
            _vm._v(" "),
            _vm._m(8),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n          Im hier verwendeten Penman-Modell wird der unbekannte Oberflächen-Dampfdruck trickreich eliminiert\n          und das Verhältnis direkt als Funktion der Temperatur, der relativen Feuchte und der\n          Windgeschwindigkeit ausgedrückt. Zusammen mit der bekannten Summe erlaubt dies eine Berechnung des\n          Verdunstungs-Energieflusses allein und damit der Verdunstungsrate in Abhängigkeit der Einstrahlung,\n          der Wolkenbedeckung, der Temperatur, der relativen Feuchte und der Windgeschwindigkeit. All diese\n          Größen sind in den Wetterstations- oder Prognosedaten enthalten oder können aus ihnen berechnet\n          werden.\n      "
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n          Aber natürlich sind die Wetterstationen nicht für Kletterer gemacht und ihre Daten können deshalb\n          keine Auskunft über die Unterschiede zwischen den Kletterfelsen Falkenstein und Seife geben. Diese\n          Differenzierung wird hier durch folgende Annahmen realisiert:\n      "
              )
            ]),
            _vm._v(" "),
            _vm._m(9),
            _vm._v(" "),
            _c("h4", [_vm._v("Feuchtetransport im Fels")]),
            _vm._v(" "),
            _vm._m(10),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n          Die gerade berechnete Verdunstung bei pitschnassem Fels und die Niederschlagsrate (Regen oder Schnee\n          in mm pro Zeiteinheit) dienen als Triebkräfte im zweiten Berechnungsmodul der Felsampel, dem\n          Feuchtetransport. Auch hier ist ein Erhaltungssatz die Grundlage, nur ist es diesmal nicht die\n          Energie, sondern die Wassermenge. Die daraus folgende Bilanz an der Felsoberfläche enthält vier\n          Anteile:\n      "
              )
            ]),
            _vm._v(" "),
            _vm._m(11),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n          Der Feuchtigkeitsfluss in bzw. aus dem Fels treibt im Prinzip eine sogenannte\n          Diffusions-Transport-Gleichung des Feuchteflusses im Fels an, welche auch noch mit einer\n          Diffusionsgleichung für die Temperatur gekoppelt ist, wenn man die bisher vernachlässigten\n          Energieströme in bzw. aus dem Fels mit einbezieht. All dies hängt zudem von allen möglichen\n          Klüftungen und Spalten im Fels ab: Viel zu kompliziert und viele Größen nicht mess- oder\n          abschätzbar!\n      "
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                '\n          Glücklicherweise konnte "der Physiker" die Gleichungen radikal vereinfachen und trotzdem in einem\n          Testbeispiel eine nahezu 100%ige Übereinstimmung mit den Ergebnissen einer kommerziellen Feuchte-\n          und Wärmetransport-Software (WUFI) erzielen:\n      '
              )
            ]),
            _vm._v(" "),
            _vm._m(12),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                '\n          Naturgemäß hängt dieses Modul "Feuchtetransport" nur vom Fels und damit vom Gebiet, aber nicht von\n          der Lage ab: Die lageabhängige Randbedingung "Verdunstungsrate" kommt ja aus Modul 1. Für\n          "Schwerkletterer", die bevorzugt an Überhängen klettern, könnte man aber die "relative\n          Regengeschütztheit" einführen, indem man den Niederschlag mit eins abzüglich dieser Größe\n          multipliziert. Aber auch nahezu 100% regengeschützter Fels (Sammlerwand) kann im Extremfall durch\n          inneres oder äußeres Schwitzen (s.o.) feucht werden.\n      '
              )
            ]),
            _vm._v(" "),
            _c("h4", [_vm._v("Datenfütterung")]),
            _vm._v(" "),
            _vm._m(13),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n          Das Felsampel-Feuchtemodell ist nutzlos, wenn es nicht mit verschiedensten Daten für den\n          Echtzeitbetrieb und zur Schätzung seiner Parameter versorgt wird.\n      "
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n          Im Endausbauzustand des Projekts erhält das Modell folgende Daten:\n      "
              )
            ]),
            _vm._v(" "),
            _vm._m(14),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n          Nur die ersten beiden Datenkategorien sind für einen Realtime-Betrieb der Felsampel nötig. Die\n          Felsfeuchtemessungen und der Online-Fragebogen dienen der Offline-Schätzung der Modellparameter.\n      "
              )
            ]),
            _vm._v(" "),
            _vm._m(15),
            _vm._v(" "),
            _c("h5", [_vm._v("Datenfusion")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n          Die Datenfusion, also das Zusammenbringen und Konsistentmachen der Daten, ist durchaus tricky:\n      "
              )
            ]),
            _vm._v(" "),
            _vm._m(16),
            _vm._v(" "),
            _c("a", { attrs: { id: "presentation" } }),
            _vm._v(" "),
            _c("h3", [_vm._v("Darstellung")]),
            _vm._v(" "),
            _c("h4", [_vm._v("Lage")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                '\n          Die Felsfeuchte hängt nicht nur vom Gebiet ab, sondern auch davon, ob die Wände sonnig oder\n          schattig sind und ob das Ziel der Begierde eher Seife oder Falkenstein heißt. Deshalb bietet\n          die Felsampel zwei weitere Kriterien zur Beurteilung "am grünem Tisch" an:\n      '
              )
            ]),
            _vm._v(" "),
            _vm._m(17),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n          Die Farben der Hauptkarte entsprechen der Klettereignung in sonniger Mittellage. Sowohl die\n          Exposition als auch die Sonnigkeit hängen zusätzlich davon ab, ob der Felsen freistehend oder im\n          Wald versteckt ist. Dadurch kann auch ein und derselbe Fels im Winter eine bessere Exposition und\n          Sonnigkeit bekommen, wenn er von Laubwald umgeben ist, durch den im Winter die Sonne scheint.\n      "
              )
            ]),
            _vm._v(" "),
            _c("h4", [_vm._v("Prognose")]),
            _vm._v(" "),
            _c(
              "p",
              [
                _vm._v(
                  "\n          Der Prognosebalken gibt eine Vier-Tage-Prognose, beginnend mit der Gegenwart bzw. der Zeit\n          "
                ),
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "region",
                        params: {
                          regionId: _vm.$route.params.regionId,
                          levelId: _vm.$route.params.levelId,
                          section: "last-update"
                        }
                      }
                    }
                  },
                  [_vm._v("des Datenstandes")]
                ),
                _vm._v(".\n      ")
              ],
              1
            ),
            _vm._v(" "),
            _vm._m(18),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                '\n          Im Gegensatz zu den Kurven des "zeitlichen Verlaufs" ist die Prognose stundengenau. Dies ist\n          wichtig,\n          wenn man beispielsweise in der Früh bei Regen aufwacht (alle Ampeln rot), aber eine\n          Wetterbesserung im Tagesverlauf, speziell im Sommer und ohne Grund-Durchfeuchtung, durchaus die\n          Ampeln auf gelb oder sogar grün stellen kann.\n      '
              )
            ]),
            _vm._v(" "),
            _vm._m(19),
            _vm._v(" "),
            _c("h4", [_vm._v("Ampelskala")]),
            _vm._v(" "),
            _c("table", { staticClass: "score-description" }, [
              _c("tbody", [
                _vm._m(20),
                _vm._v(" "),
                _vm._m(21),
                _vm._v(" "),
                _c("tr", [
                  _c("th", [_vm._v("Beschreibung")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "vertical" }, [
                    _c(
                      "div",
                      [
                        _c("felsampel-score-description", {
                          attrs: { score: 10 }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "vertical" }, [
                    _c(
                      "div",
                      [
                        _c("felsampel-score-description", {
                          attrs: { score: 9 }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "vertical" }, [
                    _c(
                      "div",
                      [
                        _c("felsampel-score-description", {
                          attrs: { score: 8 }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "vertical" }, [
                    _c(
                      "div",
                      [
                        _c("felsampel-score-description", {
                          attrs: { score: 7 }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "vertical" }, [
                    _c(
                      "div",
                      [
                        _c("felsampel-score-description", {
                          attrs: { score: 6 }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "vertical" }, [
                    _c(
                      "div",
                      [
                        _c("felsampel-score-description", {
                          attrs: { score: 5 }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "vertical" }, [
                    _c(
                      "div",
                      [
                        _c("felsampel-score-description", {
                          attrs: { score: 4 }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "vertical" }, [
                    _c(
                      "div",
                      [
                        _c("felsampel-score-description", {
                          attrs: { score: 3 }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "vertical" }, [
                    _c(
                      "div",
                      [
                        _c("felsampel-score-description", {
                          attrs: { score: 2 }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "vertical" }, [
                    _c(
                      "div",
                      [
                        _c("felsampel-score-description", {
                          attrs: { score: 1 }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "vertical" }, [
                    _c(
                      "div",
                      [
                        _c("felsampel-score-description", {
                          attrs: { score: 0 }
                        })
                      ],
                      1
                    )
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("p", [
              _c(
                "a",
                {
                  on: {
                    click: function($event) {
                      _vm.showLongExplanation = false
                    }
                  }
                },
                [
                  _vm._v(
                    "Weniger Details zu Feuchtemodellierung und Darstellung\n          anzeigen."
                  )
                ]
              )
            ])
          ],
      _vm._v(" "),
      _c("h3", [_vm._v("Datenbasis")]),
      _vm._v(" "),
      _vm._m(22),
      _vm._v(" "),
      _vm._m(23),
      _vm._v(" "),
      _vm._m(24),
      _vm._v(" "),
      _vm._m(25),
      _vm._v(" "),
      _vm._m(26)
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "sbb-logo",
        attrs: { href: "https://bergsteigerbund.de/" }
      },
      [
        _c("img", {
          attrs: { alt: "SBB Logo", src: "resources/pictures/sbb_logo.svg" }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n      Solltet ihr kein Smartphone dabei haben oder im Funkloch unterwegs sein, könnt ihr den Fragebogen auf\n      einem Zettel ausfüllen. Hierfür haben wir eine\n      "
      ),
      _c(
        "a",
        {
          attrs: {
            href: "resources/downloads/Fragebogen.pdf",
            target: "_blank"
          }
        },
        [_vm._v("Druckvorlage zum Download")]
      ),
      _vm._v(" vorbereitet.\n  ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "form",
      {
        attrs: {
          action: "https://www.paypal.com/cgi-bin/webscr",
          method: "post",
          target: "_blank"
        }
      },
      [
        _c("input", {
          attrs: { type: "hidden", name: "cmd", value: "_s-xclick" }
        }),
        _vm._v(" "),
        _c("input", {
          attrs: {
            type: "hidden",
            name: "hosted_button_id",
            value: "3WTGGWXQ7LU3J"
          }
        }),
        _vm._v(" "),
        _c("input", {
          attrs: {
            type: "image",
            src:
              "https://www.paypalobjects.com/de_DE/DE/i/btn/btn_donate_SM.gif",
            border: "0",
            name: "submit",
            title: "PayPal - The safer, easier way to pay online!",
            alt: "Spenden mit dem PayPal-Button"
          }
        }),
        _vm._v(" "),
        _c("img", {
          attrs: {
            alt: "",
            border: "0",
            src: "https://www.paypal.com/de_DE/i/scr/pixel.gif",
            width: "1",
            height: "1"
          }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _vm._v(
          "\n              Berechnung der Evaporation, also der Verdunstungsrate, an der Oberfläche,\n          "
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "\n              Modellierung der Feuchtebilanz an der Oberfläche und des Feuchtetransports im Fels, wobei die\n              Verdunstung als Randbedingung wirkt.\n          "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _vm._v(
          "\n              Schaue ich bei einer Ostwand auf sonnig oder schattig?\n          "
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "\n              Sehe ich für die Kletterfelsen Lok oder Steinschleuder die beste Vorhersage im Gebiet Rathen,\n              oder trifft die Vorhersage im Gebiet der Steine aufgrund besserer Felsqualität eher zu?\n          "
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "\n              Gilt für den Kletterfelsen Nonne der umgekehrte Fall?\n          "
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "\n              Was ist, wenn ich in Überhängen klettere? Kann der Fels dort auch feucht werden?\n          "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _vm._v(
          "\n              Berechnung der Evaporation, also der Verdunstungsrate,\n          "
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "\n              Modellierung der Feuchtebilanz an der Oberfläche und des Feuchtetransports im Fels, wobei die\n              Evaporation als Randbedingung wirkt.\n          "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "picture" }, [
      _c("img", {
        attrs: {
          src: "resources/pictures/Energiefluesse.png",
          alt: "Bild der Energieflüsse",
          width: "70%"
        }
      }),
      _vm._v(
        "\n          Die wichtigsten Energieflüsse bei der Ermittlung der Verdunstungsrate\n      "
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _vm._v(
          "\n              Die eben vorgestellte Sonneneinstrahlung,\n          "
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          '\n              den direkt oder diffus reflektierten Anteil, der von der "Felsalbedo" abhängt (bei schwarzem\n              Edelfels nahe null, ansonsten ein Modellparameter),\n          '
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          '\n              den als Wärmestrahlung in Abhängigkeit von der Oberflächentemperatur im Infraroten abgestrahlten\n              Energiefluss. Dieser ist deutlich kleiner als der unter Physikern wohlbekannte\n              Stefan-Boltzmann-Fluss, da der Wasserdampf einen Großteil der Wärmestrahlung zurückreflektiert,\n              vor allem bei hoher Luftfeuchte und Wolkenbedeckung (Treibhauseffekt!) und nur die Differenz\n              ("Netto-Ausstrahlung") für die Berechnung der Felsfeuchte relevant ist,\n          '
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          '\n              den "Konvektionsanteil" des Energiestroms, der die Luft erwärmt. Dieser steigt stark mit der\n              Windgeschwindigkeit an, da dann die erwärmte Luft schnell abtransportiert wird,\n          '
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          '\n              und schließlich den uns eigentlich interessierenden Anteil, der die Verdunstung der Felsfeuchte\n              bewirkt. Dieser steigt ebenfalls mit der Windgeschwindigkeit an und ist außerdem umso höher, je\n              mehr sich die relative Luftfeuchtigkeit von 100% unterscheidet. Die Verdunstungsrate kann\n              übrigens negativ werden: Dann "schwitzt" der Fels an der Oberfläche, vor allem in den frühen\n              Morgenstunden.\n          '
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n          Für den Fall von Wasseroberflächen bzw. an der Oberfläche pitschnassem Fels kann man aus der\n          Thermodynamik herleiten, dass das"
      ),
      _c("i", [_vm._v("Verhältnis")]),
      _vm._v(
        " des konvektiven und des Verdunstungsanteils nur\n          vom Dampfdruck direkt an der Oberfläche sowie dem Dampfdruck und Sättigungsdampfdruck in der Luft\n          abhängt.\n      "
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _vm._v(
          "\n              Für mittlere Lagen und sonnigen Fels werden die Stationsdaten (bzw. die gewichtete Mittelung der\n              Daten der benachbarten Stationen) direkt genommen.\n          "
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "\n              Für schattigen Fels wird für die Einstrahlung nur der diffuse Anteil anstelle des Gesamtanteils\n              verwendet (bei vollständig bedecktem Himmel gibt es keinen Unterschied).\n          "
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "\n              Für Tallagen wird die Windgeschwindigkeit und der Abstand der relativen Feuchte zu 100%\n              halbiert, für exponierte Lagen werden beide Größen mit dem Faktor 1,5 multipliziert.\n          "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "picture" }, [
      _c("img", {
        attrs: {
          src: "resources/pictures/Feuchtetransport.png",
          alt: "Bild des Feuchtetransports",
          width: "50%"
        }
      }),
      _vm._v(
        "\n          Bilanz der Wasserströme zur Modellierung der Felsfeuchte\n      "
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _vm._v(
          "\n              Ankommender Niederschlag direkt aus den Mess- oder Prognosedaten,\n          "
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "\n              Anteil des Niederschlags, welcher direkt abfließt, ohne in den Fels einzudringen. Der\n              Anteilswert ist ein Modellparameter und im kompakten Steine- oder Bielatalfels höher als im\n              saugfähigen Rathener Fels. Bei Dauerregen ist der Fels irgendwann feuchtegesättigt (nahezu alles\n              fließt ab). Die Modellierung dieser Sättigung bedarf eines weiteren Modellparameters,\n          "
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v("\n              Verdunstung an der Oberfläche,\n          ")
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "\n              Feuchtigkeitsfluss in bzw. aus dem Fels.\n          "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _vm._v(
          "\n              Der eingedrungene Niederschlag macht die Oberfläche sofort, den Fels in der Tiefe nach etwa\n              einem halben Tag (=Modellparameter) feucht bzw. nass.\n          "
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          '\n              Die Verdunstung trocknet den Fels. Allerdings kann man die im ersten Modul berechnete\n              Penman-Verdunstungsrate nicht direkt nehmen, da diese nur bei tropfnasser Oberfläche gilt: Je\n              trockener der Fels, desto weniger Wasser kann durch Verdunstung noch entweichen. Dies führt ohne\n              Regen zu einem exponentiellen Abfall der Felsfeuchte (genauer: der Differenz zum\n              Mindestwassergehalt), wobei die "Halbwertszeit" der Restfeuchte proportional zum Kehrwert der\n              Penman-Verdunstungsrate ist. Die Proportionalitätsfaktoren für die Oberfläche und die Tiefe sind\n              zwei weitere Modellparameter.\n          '
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "picture" }, [
      _c("img", {
        attrs: {
          src: "resources/pictures/Wetterstation_Cunnersdorf.png",
          alt: "Bild der Sachsenforst-Wetterstation in Cunnersdorf"
        }
      }),
      _vm._v("\n          Sachsenforst-Wetterstation in Cunnersdorf\n      ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _vm._v(
          '\n              Historische Wetterdaten vom mehreren Stationen des Deutschen Wetterdienstes (DWD), von zwei\n              Sachsenforst-Dauermessstellen nahe des Zeughauses und in Cunnersdorf (Abbildung) und von eigenen\n              Felsampel-Wetterstationen. Mindestens hundert Tage an historischen Stundendaten sind nötig,\n              damit das Modell unabhängig von den unbekannten Anfangsbedingungen wird und sich "warmlaufen"\n              kann. Die Daten gehen meist bis eine Stunde an die Gegenwart heran.\n          '
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "\n              Prognosedaten vom DWD an mehreren Orten im Untersuchungsgebiet. Der Prognosehorizont beträgt 10\n              Tage, von denen wir vier Tage nutzen bzw. darstellen,\n          "
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "\n              direkte Felsfeuchtemessungen des Felsampelprojekts (siehe Abbildung unten),\n          "
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "\n              subjektive Felsfeuchtebeurteilungen von erfahrenen Kletterern vor Ort.\n          "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "picture" }, [
      _c("img", {
        attrs: {
          src: "resources/pictures/feldtest_feuchtesensoren.jpg",
          alt: "Testaufbau einer Feuchtemessung direkt am Fels"
        }
      }),
      _vm._v("\n          Testaufbau einer Feuchtemessung im Sandstein\n      ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _vm._v(
          "\n              Jede Station enthält verschiedene Messgrößen. Manche haben nur den Niederschlag, andere das\n              volle Programm,\n          "
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "\n              dieselben Messgrößen gibt es in unterschiedlichen Formaten, z.B. Temperatur in Grad Celsius oder\n              Kelvin, die relative Feuchte direkt oder über den Taupunkt,\n          "
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "\n              die verschiedenen Datenquellen benutzen unterschiedliche Zeitzonen und -stempel,\n          "
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "\n              selbst für ein und dieselbe Größe an derselben Station gibt es häufig unterschiedliche\n              Formate/Zeitzonen für die historischen Daten (bis 1 Tag an die Gegenwart), den nahezu aktuellen\n              Daten (bis eine Stunde an die Gegenwart) und den Prognosedaten.\n          "
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "\n              Ferner werden die Daten nicht an den interessierenden Orten (Rathen, Affensteine usw.) erhoben,\n              sondern müssen von benachbarten Stationen interpoliert werden.\n          "
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "\n              Schließlich wollen unplausible Daten erkannt und Datenlücken durch Messfehler, Ausfälle oder\n              auch die manchmal vorkommende Zeitlücke zwischen den historischen und den prognostizierten Daten\n              überbrückt sein.\n          "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _c("strong", [_vm._v("Exposition:")]),
        _vm._v(
          " Tallage, Mittellage oder exponierte Lage. Tallagen werden durch\n                                           weniger Wind und eine größere relative Feuchte\n                                           modelliert, exponierte Lagen umgekehrt.\n          "
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _c("strong", [_vm._v("Sonnigkeit:")]),
        _vm._v(
          " Diese hängt nicht nur von der Himmelsrichtung ab, sondern auch\n                                           von der anvisierten Tageszeit (Ostwände am Morgen, Westwände\n                                           am Abend) und davon, ob Vorsprünge oder benachbarte Felsen die\n                                           Wand abschatten. Die Sonnigkeit beeinflusst bei der Modellierung\n                                           die Differenz zwischen gesamter und diffuser Einstrahlung. Bei\n                                           bedecktem Himmel oder in der Nacht gibt es keinen Unterschied.\n          "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _vm._v(
          "\n              In der Hauptkarte wird für die Prognose eine sonnige Mittellage angenommen,\n          "
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "\n              in der Detailbeschreibung einer Region ändert sie sich mit der oben eingestellten Lage.\n          "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n          Für die Prognose wird dasselbe Felsfeuchtemodell wie für die Vergangenheit und die Gegenwart\n          angewandt, aber anstelle der Messdaten mit vom "
      ),
      _c("a", { attrs: { href: "https://www.dwd.de/" } }, [_vm._v("DWD")]),
      _vm._v(
        ' gelieferten\n          Prognosedaten "gefüttert". Aber, trotz Hochleistungsrechner, Terabyte an Daten und einem\n          '
      ),
      _c("a", { attrs: { href: "resources/downloads/dwd_wettemodelle.pdf" } }, [
        _vm._v("dreistufigen Wettermodell")
      ]),
      _vm._v(
        ' gilt weiterhin, was\n          schon Mark Twain oder wahlweise Karl Valentin wussten: "Prognosen sind schwierig, besonders wenn sie\n          die Zukunft betreffen."\n      '
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("Farbe")]),
      _vm._v(" "),
      _c("td", { staticClass: "score-description-score-0" }),
      _vm._v(" "),
      _c("td", { staticClass: "score-description-score-1" }),
      _vm._v(" "),
      _c("td", { staticClass: "score-description-score-2" }),
      _vm._v(" "),
      _c("td", { staticClass: "score-description-score-3" }),
      _vm._v(" "),
      _c("td", { staticClass: "score-description-score-4" }),
      _vm._v(" "),
      _c("td", { staticClass: "score-description-score-5" }),
      _vm._v(" "),
      _c("td", { staticClass: "score-description-score-6" }),
      _vm._v(" "),
      _c("td", { staticClass: "score-description-score-7" }),
      _vm._v(" "),
      _c("td", { staticClass: "score-description-score-8" }),
      _vm._v(" "),
      _c("td", { staticClass: "score-description-score-9" }),
      _vm._v(" "),
      _c("td", { staticClass: "score-description-score-10" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("Klettereignung")]),
      _vm._v(" "),
      _c("td", { staticClass: "vertical" }, [_c("div", [_vm._v("100%")])]),
      _vm._v(" "),
      _c("td", { staticClass: "vertical" }, [_c("div", [_vm._v("90%")])]),
      _vm._v(" "),
      _c("td", { staticClass: "vertical" }, [_c("div", [_vm._v("80%")])]),
      _vm._v(" "),
      _c("td", { staticClass: "vertical" }, [_c("div", [_vm._v("70%")])]),
      _vm._v(" "),
      _c("td", { staticClass: "vertical" }, [_c("div", [_vm._v("60%")])]),
      _vm._v(" "),
      _c("td", { staticClass: "vertical" }, [_c("div", [_vm._v("50%")])]),
      _vm._v(" "),
      _c("td", { staticClass: "vertical" }, [_c("div", [_vm._v("40%")])]),
      _vm._v(" "),
      _c("td", { staticClass: "vertical" }, [_c("div", [_vm._v("30%")])]),
      _vm._v(" "),
      _c("td", { staticClass: "vertical" }, [_c("div", [_vm._v("20%")])]),
      _vm._v(" "),
      _c("td", { staticClass: "vertical" }, [_c("div", [_vm._v("10%")])]),
      _vm._v(" "),
      _c("td", { staticClass: "vertical" }, [_c("div", [_vm._v("0%")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n      Wir bedanken uns für die freundliche Unterstützung mit Wetterdaten durch den\n      "
      ),
      _c(
        "a",
        {
          attrs: {
            href:
              "https://www.sbs.sachsen.de/versuchs-und-forschungsinstitution-7187.html"
          }
        },
        [_vm._v("Staatsbetrieb Sachsenforst")]
      ),
      _vm._v(",\n      das\n      "),
      _c(
        "a",
        {
          attrs: {
            href:
              "https://www.landwirtschaft.sachsen.de/Wetter09/asp/inhalt.asp?seite=uebersicht"
          }
        },
        [
          _vm._v(
            "\n          Landesamt für Umwelt, Landwirtschaft und Geologie"
          )
        ]
      ),
      _vm._v("\n      mit Hilfe von "),
      _c("a", { attrs: { href: "https://opensensorweb.de/" } }, [
        _vm._v("OpenSensorWeb")
      ]),
      _vm._v("\n      und den\n      "),
      _c("a", { attrs: { href: "https://www.dwd.de/" } }, [
        _vm._v("deutschen Wetterdienst")
      ]),
      _vm._v(
        ",\n\n      sowie für die Infrastruktur der Wetterstation bei der\n      "
      ),
      _c("a", { attrs: { href: "https://www.berggast.de/" } }, [
        _vm._v("BERGGAST Bergwirtschaft Papststein")
      ]),
      _vm._v(
        ".\n\n      Für den Zugriff auf die Ergebnisse des Feuchtemodels durch Drittanwendungen ist das\n      "
      ),
      _c(
        "a",
        {
          attrs: {
            href:
              "https://felsampel.bergsteigerbund.de/calculation/v8/results/docs/"
          }
        },
        [_vm._v("Datenformat dokumentiert")]
      ),
      _vm._v("\n      und die Verwendung nicht eingeschränkt.\n  ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "supporter-logo-bar" }, [
      _c(
        "a",
        {
          attrs: {
            href:
              "https://www.sbs.sachsen.de/versuchs-und-forschungsinstitution-7187.html"
          }
        },
        [
          _c("img", {
            attrs: {
              alt: "Wald Bewegt Logo des Sachsenforst",
              src: "resources/pictures/sachsenforst_logo.png"
            }
          })
        ]
      ),
      _vm._v(" "),
      _c("a", { attrs: { href: "https://www.dwd.de/" } }, [
        _c("img", {
          attrs: {
            alt: "Logo des Deutschen Wetterdienst",
            src: "resources/pictures/dwd_logo_258x69.png"
          }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "supporter-logo-bar" }, [
      _c("a", { attrs: { href: "https://www.berggast.de/" } }, [
        _c("img", {
          attrs: {
            alt: "Logo der Berggast Bergwirtschaft Papststein",
            src: "resources/pictures/berggast.png"
          }
        })
      ]),
      _vm._v(" "),
      _c(
        "a",
        {
          attrs: {
            href:
              "https://www.landwirtschaft.sachsen.de/Wetter09/asp/inhalt.asp?seite=uebersicht"
          }
        },
        [
          _c("img", {
            attrs: {
              alt: "Logo des LfULG",
              src: "resources/pictures/LfULG_logo.png"
            }
          })
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "supporter-logo-bar" }, [
      _c("a", { attrs: { href: "https://opensensorweb.de/" } }, [
        _c("img", {
          attrs: {
            alt: "Logo von Pikobytes",
            src: "resources/pictures/pikobytes_logo.png"
          }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer" }, [
      _c("a", { attrs: { href: "privacy.html" } }, [
        _vm._v("Datenschutzerklärung und Impressum")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }