import {IRegionList} from "./regions";
import {ISignalLevels} from "./signals";

// chart.js
import {
    CartesianScaleOptions,
    Chart,
    ChartEvent,
    ChartOptions,
    ChartType,
    Legend,
    LegendItem,
    LinearScale,
    LineController,
    LineElement,
    Plugin,
    PointElement,
    ScatterDataPoint,
    TimeScale,
    Tooltip,
    TooltipItem,
    Filler,
} from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import "chartjs-adapter-moment";

// moment.js
// tslint:disable-next-line:no-var-requires
import * as moment from "moment";

import styles from "./colors.module.scss";

// for old browser compatibility
import "abortcontroller-polyfill";
import "core-js/features/array/find";
import "core-js/features/object/assign";
import "core-js/features/object/keys";
import "core-js/features/promise";
import "whatwg-fetch";

export enum Periods {
    daily,
    hourly,
}

interface IHistoryRow {
    "RsDiff": number;
    "RsOpen": number;
    "T[K]": number;
    "clRel": number;
    "d2today": number;
    "evp[mm]": number;
    "humDeep": number;
    "humRel": number;
    "humSurf": number;
    "levDeep": number;
    "levSurf": number;
    "levClimb": number;
    "rain_mm": number;
    "sun_h": number;
    "windSI": number;
}

interface IHistoryRawData {
    [key: string]: IHistoryRow;
}

interface IColorMap {
    [key: string]: string;
}

interface IScoreAxisOptions {
    width?: number;
    minScore?: number;
    maxScore?: number;
    axis: string;
    colorsMap: IColorMap;
    showBadLabel?: string;
    showGoodLabel?: string;
    labelPadding?: number;
    labelStyle?: string;
    labelFont?: string;
}

declare module 'chart.js' {
    interface PluginOptionsByType<TType extends ChartType> {
        scoreAxis: IScoreAxisOptions;
    }
}

interface IExtendedChartOptions extends ChartOptions {
    scoreAxis?: IScoreAxisOptions;
}

Chart.register(
    annotationPlugin,
    Filler,
    Legend,
    LineController,
    LinearScale,
    LineElement,
    PointElement,
    TimeScale,
    Tooltip,
);


// show a horizontal line behind the mouse cursor
const indexLinePlugin: Plugin = {
    id: "indexLine",
    afterDraw(chart: Chart) {
        // @ts-ignore
        const tooltip = chart.tooltip as any;
        if (tooltip._active && tooltip._active.length) {
            const chartArea = chart.chartArea;
            const activePoint = tooltip._active[0];
            const ctx = chart.ctx;
            const x = activePoint.element.x;
            const topY = chartArea.top;
            const bottomY = chartArea.bottom;

            // draw line
            ctx.save();
            ctx.beginPath();
            ctx.moveTo(x, topY);
            ctx.lineTo(x, bottomY);
            ctx.lineWidth = 2;
            ctx.strokeStyle = "red";
            ctx.stroke();
            ctx.restore();
        }
    }
}


// auto hide all unused scales / axes
const hideUnusedScalesPlugin: Plugin = {
    id: "hideUnusedScales",
    beforeLayout(chart: Chart) {
        const chartScales = chart.options.scales;

        if (!chartScales) {
            throw Error("Could not get chartScales.");
        }

        if (!chart.config.data || !chart.config.data.datasets) {
            throw Error("Could not get datasets.");
        }

        // hide all axes
        for (const scale of Object.values(chartScales)) {
            if (scale && (scale as any).axis === "y") {
                scale.display = false;
            }
        }

        // show wanted axes again
        chart.config.data.datasets.forEach((dataset) => {
            for (const [axisId, scale] of Object.entries(chartScales)) {
                if (scale) {
                    if (axisId === (dataset as any).yAxisID && !dataset.hidden) {
                        scale.display = true;
                    }
                }
            }
        });
    }
}


// show score colors behind axis
const scoreAxisPlugin: Plugin = {
    id: "scoreAxis",
    beforeDraw(chart) {
        const options = {
            axis: "y",
            minScore: 0,
            maxScore: 10,
            width: 10,
            showBadLabel: "Schlecht",
            showGoodLabel: "Gut",
            labelPadding: 5,
            labelStyle: "#666",
            labelFont: "11px sans-serif",
            ...chart.config.options?.plugins?.scoreAxis,
        };

        const chartOptions = chart.options;
        if (!chartOptions.scales || !chartOptions.scales[options.axis]) {
            throw Error("Scales undefined.");
        }

        const axisConfig = chartOptions.scales[options.axis] as CartesianScaleOptions;
        const axisInstance = (chart as any).scales[options.axis];

        if (!axisConfig) {
            throw Error("AxisConfig undefined.");
        }

        if (options && options.colorsMap && axisConfig.display) {
            const ctx = chart.ctx;
            const totalHeight = axisInstance.bottom - axisInstance.top;
            let topDistance = axisInstance.top;

            if (!ctx) {
                throw Error("Context undefined");
            }

            for (let score = options.minScore; score <= options.maxScore; score++) {

                ctx.save();
                let boxHeight = totalHeight / 10;
                if (score === options.minScore || score === options.maxScore) {
                    boxHeight /= 2;
                }

                ctx.fillStyle = options.colorsMap[`score-${score}-color`] as string;
                if (axisConfig.position === "left") {
                    ctx.fillRect(
                        axisInstance.right - options.width,
                        topDistance + 1,
                        options.width,
                        boxHeight - 2);
                } else if (axisConfig.position === "right") {
                    ctx.fillRect(
                        axisInstance.left,
                        topDistance + 1,
                        options.width,
                        boxHeight - 2);
                }
                ctx.restore();

                if (score === options.minScore && options.showGoodLabel) {
                    ctx.save();
                    ctx.fillStyle = options.labelStyle as string;
                    ctx.font = options.labelFont as string;
                    if (axisConfig.position === "left") {
                        ctx.textAlign = "right";
                        ctx.translate(axisInstance.left + (options.labelPadding || 0), topDistance);
                        ctx.rotate(-Math.PI / 2);
                        ctx.fillText(options.showGoodLabel, 0, 0);
                    } else if (axisConfig.position === "right") {
                        ctx.textAlign = "left";
                        ctx.translate(axisInstance.right - (options.labelPadding || 0), topDistance);
                        ctx.rotate(Math.PI / 2);
                        ctx.fillText(options.showGoodLabel, 0, 0);
                    }
                    ctx.restore();
                }

                if (score === options.maxScore && options.showBadLabel) {
                    ctx.save();
                    ctx.fillStyle = options.labelStyle as string;
                    ctx.font = options.labelFont as string;
                    if (axisConfig.position === "left") {
                        ctx.textAlign = "left";
                        ctx.translate(axisInstance.left + (options.labelPadding || 0), topDistance + boxHeight);
                        ctx.rotate(-Math.PI / 2);
                        ctx.fillText(options.showBadLabel, 0, 0);
                    } else if (axisConfig.position === "right") {
                        ctx.textAlign = "right";
                        ctx.translate(axisInstance.right - (options.labelPadding || 0), topDistance + boxHeight);
                        ctx.rotate(Math.PI / 2);
                        ctx.fillText(options.showBadLabel, 0, 0);
                    }
                    ctx.restore();
                }

                topDistance += boxHeight;

            }

        }
    },
}


export class FelsampelHistoryChart {

    private readonly period: Periods;
    private readonly chart: Chart;
    private controller = new AbortController();

    constructor(element: HTMLCanvasElement, period: Periods) {

        const now = new Date();
        this.period = period;
        this.chart = new Chart(element, {
            type: "line",
            data: {
                datasets: [],
            },
            options: {
                animation: {
                    duration: 0,
                },
                scales: {
                    x: {
                        type: "time",
                        time: {
                            unit: period === Periods.daily ? "month" : "day",
                            displayFormats: {
                                day: "dd. DD.MM.",
                                month: "MMM YYYY",
                            }
                        },
                        ticks: {
                            autoSkipPadding: 25,
                            maxRotation: 0,
                            align: "start",
                        },
                    },
                    y_axis_rel_hum: {
                        position: "right",
                        title: {
                            display: true,
                            text: "Klettereignung bzgl. Feuchtigkeit [%]",
                        },
                        min: 0,
                        max: 100,
                        ticks: {
                            padding: 5,
                        },
                        grid: {
                            lineWidth: 3,
                        },
                    },

                    y_axis_sun_rain: {
                        position: "left",
                        title: {
                            display: true,
                            text: period === Periods.daily
                                ? "Niederschlag [mm/Tag]  /  Sonnenstunden [h/Tag]"
                                : "Niederschlag [mm/h]  /  relative Sonnenscheindauer [0-1]",
                        },
                        min: 0,
                        suggestedMax: period === Periods.daily ? 50 : 2.2,
                        grid: {
                            display: false,
                        },
                    },
                    y_axis_tmp: {
                        position: "left",
                        title: {
                            display: true,
                            text: "Temperatur [°C]",
                        },
                        suggestedMin: -20,
                        suggestedMax: 50,
                        grid: {
                            display: false,
                        },
                    },
                    y_axis_hum: {
                        suggestedMin: 0,
                        suggestedMax: 80,
                        reverse: true,
                        grid: {
                            display: false,
                        },
                        title: {
                            display: true,
                            text: "Feuchtigkeit [kg/m³]",
                        },

                    },
                },
                plugins: {
                    tooltip: {
                        enabled: true,
                        intersect: false,
                        mode: "index",
                        callbacks: {
                            title: (items: TooltipItem<"line">[]) => {
                                const date = items[0].parsed.x
                                if (period === Periods.daily) {
                                    return moment(date).format("LL");
                                } else if (period === Periods.hourly) {
                                    return moment(date).format("LLL");
                                }
                                throw Error("Unexpected period.");
                            }
                        },
                    },
                    legend: {
                        onClick: (event: ChartEvent, legendItem: LegendItem) => {
                            const datasetIndex = legendItem.datasetIndex;
                            if (!this.chart.data.datasets) {
                                throw Error("Chart.data.datasets undefined.");
                            }
                            this.chart.data.datasets[datasetIndex].hidden = !this.chart.data.datasets[datasetIndex].hidden;
                            this.chart.update();
                        },
                        labels: {
                            usePointStyle: true,
                            font: {
                                size: 11,
                            },
                        },
                    },
                    annotation: {
                        annotations: [
                            // draw today line
                            {
                                type: "box",
                                drawTime: "beforeDatasetsDraw",
                                xScaleID: "x",
                                xMin: now.getTime(),
                                backgroundColor: "rgba(238, 238, 238, .5)",
                                borderWidth: 0,
                            },
                            {
                                type: "line",
                                drawTime: "beforeDatasetsDraw",
                                scaleID: "x",
                                value: now.getTime(),
                                borderColor: "#999",
                                borderWidth: 1,
                                label: {
                                    enabled: true,
                                    content: "Jetzt",
                                    position: "start",
                                    yAdjust: 20,
                                    backgroundColor: "rgba(0,0,0,.6)",
                                },
                            },
                        ],
                    },
                    scoreAxis: {
                        axis: "y_axis_rel_hum",
                        colorsMap: styles,
                    },
                },
            },
            plugins: [
                scoreAxisPlugin,
                indexLinePlugin,
                hideUnusedScalesPlugin,
            ],
        });

        this.chart.update();

    }

    public clearDatasets() {
        this.controller.abort();
        this.controller = new AbortController();
        this.chart.data.datasets = [];
        this.chart.update();
    }

    public loadDatasets(region: keyof IRegionList, level: keyof ISignalLevels) {

        const signal = this.controller.signal;
        const baseUrl = "https://felsampel.bergsteigerbund.de/calculation/v8/results/";
        fetch(`${baseUrl}/${region}.${level}.history.${Periods[this.period]}.json`, {signal})
            .then((response) => {
                return response.json();
            })
            .then((data: IHistoryRawData) => {

                if (!this.chart.data.datasets) {
                    throw Error("Called loadDatasets before initialisation.");
                }

                this.chart.data.datasets.push({
                    label: "Klettereignung bzgl. Feuchtigkeit",
                    data: this.getData(data, "levClimb", (n) => Math.round(n * 1000) / 10),
                    backgroundColor: "rgba(0,0,0,0)",
                    pointRadius: 0,
                    pointStyle: "line",
                    borderColor: "black",
                    borderWidth: 4,
                    yAxisID: "y_axis_rel_hum",
                    tension: 0.4,
                });

                this.chart.data.datasets.push({
                    label: "Feuchtigkeit (Oberfläche)",
                    data: this.getData(data, "humSurf", (n) => Math.round(n * 10) / 10),
                    backgroundColor: "rgba(0,0,0,0)",
                    pointRadius: 0,
                    pointStyle: "line",
                    borderWidth: 1.5,
                    borderDash: [10, 5],
                    borderColor: "orange",
                    yAxisID: "y_axis_hum",
                    tension: 0.4,
                });

                this.chart.data.datasets.push({
                    label: "Feuchtigkeit (Tiefe)",
                    data: this.getData(data, "humDeep", (n) => Math.round(n * 10) / 10),
                    backgroundColor: "rgba(0,0,0,0)",
                    pointRadius: 0,
                    pointStyle: "line",
                    borderWidth: 1.5,
                    borderDash: [10, 5],
                    borderColor: "brown",
                    hidden: true,
                    yAxisID: "y_axis_hum",
                    tension: 0.4,
                });

                this.chart.data.datasets.push({
                    label: "Temperatur",
                    data: this.getData(data, "T[K]", (n) => Math.round(n - 273)),
                    backgroundColor: "rgba(0,0,0,0)",
                    borderColor: "violet",
                    pointRadius: 0,
                    pointStyle: "line",
                    borderWidth: 1.5,
                    hidden: true,
                    yAxisID: "y_axis_tmp",
                    tension: 0.4,
                });

                this.chart.data.datasets.push({
                    label: "Niederschlag",
                    data: this.getData(data, "rain_mm", (n) => Math.round(n * 100) / 100),
                    fill: true,
                    backgroundColor: "rgba(0, 128, 255, .5)",
                    pointRadius: 0,
                    pointStyle: "rect",
                    borderWidth: 1.5,
                    yAxisID: "y_axis_sun_rain",
                    tension: 0.4,
                });

                this.chart.data.datasets.push({
                    label: "Sonnenstunden",
                    data: this.getData(data, "sun_h"),
                    fill: true,
                    backgroundColor: "rgba(255, 255, 0, .5)",
                    pointRadius: 0,
                    pointStyle: "rect",
                    borderWidth: 1.5,
                    yAxisID: "y_axis_sun_rain",
                    tension: 0.4,
                });

                this.chart.update();

            })
            .catch(() => {
                // pass
            });
    }

    // noinspection JSMethodCanBeStatic
    private getData(data: IHistoryRawData, key: keyof IHistoryRow, yHook: (y: number) => number = (n) => n) {
        const arr: ScatterDataPoint[] = [];
        for (const date in data) {
            if (data.hasOwnProperty(date)) {
                arr.push({
                    x: new Date(date).getTime(),
                    y: yHook(data[date][key]),
                });
            }
        }
        return arr;
    }

}
