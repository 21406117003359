// for internet explorer compatibility
import "classlist-polyfill";
import "core-js/features/promise";
import "whatwg-fetch";

interface ISignal {
    d: number;  // deep humidity
    s: number;  // surface humidity
    c: number;  // climbing score
    p: number[];  // hourly prognosis average for this day and region
}

export const expositionNames = {
    t: "Exponiert",
    m: "Mittig",
    b: "Tallage",
};

export const shadingNames = {
    e: "Sonnig",
    s: "Schattig",
};

export const levelNames = {
    te: `${expositionNames.t} / ${shadingNames.e}`,
    ts: `${expositionNames.t} / ${shadingNames.s}`,
    me: `${expositionNames.m} / ${shadingNames.e}`,
    ms: `${expositionNames.m} / ${shadingNames.s}`,
    be: `${expositionNames.b} / ${shadingNames.e}`,
    bs: `${expositionNames.b} / ${shadingNames.s}`,
};

export interface ISignalLevels {
    te: ISignal; // top exposed
    ts: ISignal; // top shady
    me: ISignal; // middle exposed
    ms: ISignal; // middle shady
    be: ISignal; // bottom exposed
    bs: ISignal; // bottom shady
}

export interface ISignalRegion {
    l: ISignalLevels;  // only for today
    a: number;  // average for this day and region
}

export interface IRegions {
    [key: string]: ISignalRegion;
}

export interface ISignalsData {
    s: IRegions;
    u: string;   // last update
    us: string;  // last update as human readable string
}

export function getScoreNumber(score: number): number {
    return Math.round((1 - score) * 10);
}

export function getScoreDescription(score: number): string {
    return ({
         0: "ausgezeichnet",
         1: "sehr gut",
         2: "gut",
         3: "ziemlich gut",
         4: "befriedigend",
         5: "ausreichend",
         6: "gerade ausreichend",
         7: "ziemlich schlecht",
         8: "schlecht",
         9: "sehr schlecht",
        10: "extrem schlecht",
    } as {[key: number]: string})[getScoreNumber(score)];
}

type onRefreshCallback = () => void;

class Signals {

    private readonly url = "https://felsampel.bergsteigerbund.de/calculation/v8/results/signals.json";
    private response: Promise<Response> = fetch(this.url);
    private onRefreshCallables: onRefreshCallback[] = [];

    constructor() {
        this.runCallables();
    }

    public get(): Promise<ISignalsData> {
        return this.response.then((signalResponse) => signalResponse.clone().json());
    }

    public refresh() {
        this.response = fetch(this.url);
        this.runCallables();
    }

    public onLoad(callable: onRefreshCallback) {
        this.onRefreshCallables.push(callable);
        return callable();
    }

    private runCallables() {
        this.onRefreshCallables.forEach((callable) => { callable(); });
    }

}

export const signals = new Signals();
